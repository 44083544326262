async function loadConfig() {
  try {
    const response = await fetch('/api/remoteUrls');
    const config = await response.json();
    window.__remoteApps__ = config;
  } catch (error) {
    console.error('Error fetching remote urls:', error);
  }
}
(async () => {
  await loadConfig();
  await import('./bootstrap');
})();

export default {};